<template>
  <div
    id="app"
    :class="[
      'route--' + view.toLowerCase(),
      isOnTop ? 'page--is-top' : 'page--is-scrolled',
    ]"
  >
    <div
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
        { 'nav--expanded': isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="0"
          :action="redirect(['home'], { scrollTo: 0 }, this)"
          :isOpen.sync="isExpanded"
        />
      </div>
    </div>

    <transition mode="out-in" name="route">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<script>
import { NavigationComponent } from "vue-elder-navigation";
import Footer from "@/components/Footer.vue";
import Logo from "@/assets/elements/logo.png";
import { redirect } from "@/utils";
import { redirectFreia } from "@/utils";
import { redirectKontakt } from "@/utils";

export default {
  data() {
    return {
      isOnTop: true,
      isExpanded: false,
    };
  },
  computed: {
    view() {
      return this.$route.name || "";
    },
    logo() {
      return Logo;
    },
    projectName() {
      return "Freia";
    },

    navTransition() {
      return this.view === "Campaign" ? 350 : 5;
    },

    items() {
      return [
        // {
        //   label: "HJEM",
        //   action: redirect(["home"], { scrollTo: 0 }, this),
        // },
        // {
        //   label: "REGISTRERING",
        //   action: redirect(["home"], { scrollTo: 625 }, this),
        // },
        // {
        //   label: "VILKÅR",
        //   action: redirect(["terms"], { scrollTo: 0 }, this),
        // },
        // {
        //   label: "KONTAKT OSS",
        //   action: redirectKontakt,
        // },
        {
          label: "FREIA.NO",
          action: redirectFreia,
        },
        {
          label: "KONTAKT",
          action: redirectKontakt,
        },
      ];
    },
  },
  methods: {
    redirect,
    redirectFreia,
    redirectKontakt,
  },
  created() {
    const debounce = (fn) => {
      let frame;
      return (...params) => {
        if (frame) cancelAnimationFrame(frame);
        frame = requestAnimationFrame(() => fn(...params));
      };
    };
    const storeScroll = () => {
      this.isOnTop = window.scrollY <= this.navTransition;
    };

    document.addEventListener("scroll", debounce(storeScroll), {
      passive: true,
    });
    storeScroll();
  },
  components: {
    NavigationComponent,
    Footer,
  },
};
</script>

<style lang="scss">
@import "src/styles/main";

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// #app {
//   min-height: 100%;
//   position: relative;
// }

.nav {
  position: fixed;
  background: transparent;
  box-shadow: none;
  background-color: var(--secondary);

  top: 0;
  width: 100%;
  z-index: 1001;

  transition: color 0.4s ease-in-out, height 0.4s ease-in-out,
    background 0.4s ease-in-out, backdrop-filter 0.4s ease-in-out,
    -webkit-backdrop-filter 0.4s ease-in-out, transform 0.4s ease-in-out,
    -webkit-transform 0.4s ease-in-out;

  &--legal,
  &--terms {
    position: fixed;
    background: var(--secondary);
  }

  &--hero {
    .page--is-top & {
      @media (max-width: $tablet) {
        position: fixed;
        background: var(--secondary);
      }

      &:not(.nav--expanded) {
        .elder__navigation-component {
          color: var(--secondary) !important;

          @media (max-width: $tablet) {
            color: var(--primary) !important;
          }
        }
      }
    }
  }

  .container {
    padding-left: 150px;
    padding-right: 150px;

    span {
      color: var(--primary);
    }

    @media (max-width: $tablet) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (max-width: $mobile) {
      padding-left: 0px;
      padding-right: 40px;
    }
  }

  .elder__navigation-logo-image {
    max-width: 200px;

    @media (max-width: $mobile) {
      height: 80px;
    }
  }

  .page--is-scrolled & {
    position: fixed;
    background: var(--secondary);
    box-shadow: 0 10px 15px -10px rgba(black, 0.05);

    .elder__navigation-logo-image {
      transition: all 400ms ease-out;

      @media (min-width: $tablet) {
        max-height: 60px !important;
      }

      @media (max-width: $tablet) {
        max-height: 70px !important;
      }
    }

    .elder__navigation-bars {
      @media (max-width: $mobile) {
        scale: 1;
        transition: all 400ms ease-out;
      }
    }
  }

  .elder__navigation-bars {
    color: var(--primary);
    scale: 1.7;
  }

  .elder__navigation {
    button {
      margin: 0;
    }

    &-logo {
      &-image {
        transition: all 400ms ease-out;

        @media (min-width: $mobile) {
          max-width: 150px;
          max-height: 7rem !important;
        }

        @media (max-width: $mobile) {
          max-height: 110px !important;
        }
      }

      .elder__navigation-component {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &-actions {
      .elder__navigation-component {
        color: var(--primary);

        &:hover {
          cursor: pointer;
          background: none;

          &:after {
            content: "";
            background-color: var(--primary);
            width: calc(100% - 2rem);
            height: 2px;
            position: absolute;
            left: 1rem;
            bottom: 0.5rem;
          }
        }
      }
    }

    &--responsive {
      overflow-y: initial;
    }
  }

  &--expanded {
    height: 100vh;
    background-color: var(--secondary);

    .elder__navigation-actions {
      span {
        font-size: 1.2rem;
      }

      padding-left: 2.5rem;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .elder__navigation-node {
        padding: 0.5rem 2rem;
      }
    }
  }
}
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 300ms ease;
}
</style>
