<template>
  <div class="container">
    <HeroBanner />
    <Form />
  </div>
</template>

<script>
import HeroBanner from "@/components/HeroBanner.vue";
import Form from "@/components/Form.vue";

export default {
  components: {
    HeroBanner,
    Form,
  },
};
</script>

<style lang="scss"></style>
