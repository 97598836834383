<template>
  <section class="error-container">
    <div class="error-container__text">
      <h1 class="error-container__text__main-paragraph">
        OOPS!
      </h1>
      <h2>Denne siden eksisterer ikke.</h2>
    </div>

    <img class="error-container__image" src="@/assets/elements/404.png" />

    <div class="error-container__button-parent">
      <router-link to="/">
        <ButtonComponent class="error-container__button" :theme="'primary'"
          >HJEM</ButtonComponent
        >
      </router-link>
      <a
        href="https://contactus.mdlzapps.com/freiano/contact/no-NO/"
        target="_blank"
      >
        <ButtonComponent class="error-container__button" :theme="'primary'"
          >KONTAKT OSS</ButtonComponent
        >
      </a>
    </div>
  </section>
</template>
<script>
import Error from "@/components/Error.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  components: {
    Error,
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.error-container {
  position: relative;
  width: 80%;
  max-width: 1100px;
  border-radius: 25px;
  margin: auto;
  margin-top: 50px;

  text-align: center;

  padding: 75px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 90%;
  }

  &__image {
    display: block;
    margin: auto;
    width: 80%;
    max-width: 650px;
  }

  &__text {
    max-width: 800px;
    margin: auto;
    margin-top: 2rem;
  }

  p {
    color: var(--secondary);
  }

  &__button-parent {
    margin-top: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 50px;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 0px;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }
}
</style>
