<template
  ><div>
    <section class="terms-container">
      <div class="terms-container__text">
        <h1>VILKÅR</h1>

        <br />
        <br />

        <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle">
              ARRANGØR AV KONKURRANSEN <br />
              Mondelez Norge AS («Mondelez») <br />Johan Throne Holsts Plass 1,
              <br />P.O. Box 6658 Rodeløkka,<br />NO-0502, Oslo
            </p>

            <br />
            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              AKSEPT AV VILKÅR OG BETINGELSER<br />Ved å delta i konkurransen
              aksepterer deltakeren konkurransereglene. Aldersgrense for å delta
              er 18 år.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              PREMIE
              <br />
              <br />
              Premien er et kjempeegg fylt med Freia produkter til en verdi av
              500 NOK. Det trekkes en vinner hver dag i kampanjeperioden.
              Vinnerne trekkes av Mondelez. En representant fra Mondelez vil ta
              kontakt med vinnerne.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Premien må aksepteres slik som den er, og kan ikke byttes inn mot
              kontanter. Mondelez er ikke ansvarlige for eventuelle skader som
              måtte oppstå under transport av premien. Vinner svarer for
              eventuell gevinstskatt.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              KAMPANJEPERIODE
              <br />
              06.02.24 – 10.04.24
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              HVORDAN DELTA
              <br />
              <br />

              1. Gå til hjemmesiden til Freia (www.freia.no)
              <br />
              <br />

              2. Klikk deg inn på konkurransen «Vinn kjempeegget»
              <br />
              <br />

              3. Registrer deg med navn, telefon og e-postadresse
              <br />
              <br />

              3. Trykk delta, og du er med i trekningen av et kjempeegg!
            </p>

            <br />
            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              SLIK BLI VINNEREN PLUKKET UT
              <br />
              Vinnerne av konkurransen trekkes tilfeldig, basert på alle som har
              lagt igjen personopplysninger og trykket delta. Det trekkes totalt
              64 vinnere som hver får et kjempeegg fylt med Freiaprodukter.
              Beslutningen som blir tatt er endelig, og kan ikke overklages.
              Vinnerne blir kontaktet på oppgitt e-postadresse. For å kunne
              sende over premien vil vinnnerne motta et skjema på e-post som er
              gyldig i syv dager.I skjemaet fylles inn informasjonen som behøves
              for å sende vinnerne premien. Dersom vi ikke mottar utfylt skjema
              innen fristen, forbeholder vi oss retten til å diskvalifisere
              deltakeren og tildele premien til en reserve valgt ut på samme
              måte. Premien mottas innen 14 dager etter innsendt skjema.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              DISKVALIFISERING
              <br />
              Mondelez og andre partnere forbeholder seg retten til å
              diskvalifisere deltakere som bryter med konkurransereglene, deltar
              med upassende kommentarer eller forsøker å sabotere konkurransen.
              Disse deltagerne vil også bli utestengt. Upassende eller støtende
              kommentarer fjernes.
            </p>

            <br />
            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              FORBEHOLD
              <br />
              Mondelez og andre partnere i denne konkurransen tar ikke ansvar
              for eventuelle trykkfeil eller andre utilsiktete
              misforståelser.Mondelez og andre partnere kan ikke holdes
              ansvarlig for materialet som sendes inn av deltakerne i
              konkurransen.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Hvis, av en eller annen grunn, kampanjen ikke kan bli gjennomført
              som planlagt av grunner som er utenfor Mondelez sin kontroll, vil
              Mondelez reservere seg retten til å, når som helst og uten
              forvarsel, avbryte, avslutte, endre eller utsette hele eller deler
              av kampanjen.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              I den utstrekning loven tillater, vil arrangørene ikke være
              ansvarlige for kampanjen eller premien, og tar ikke særlig ansvar
              for deltakelse som er ugyldig på grunn av tekniske problemer
              utenfor arrangørens kontroll eller problemer angående tilgang til
              nettsiden.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Alle internettkostnader og andre kostnader som kan oppstå
              forbundet med deltakelse i kampanjen, er utelukkende deltakerens
              ansvar.
            </p>

            <br />
            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              PERSONOPPLYSNINGER
              <br />
              Ved deltagelse i konkurransen kommer deltakeren til å måtte gi fra
              seg visse personopplysninger, som navn og kontaktinformasjon.
              Mondelez vil behandle personopplysninger for å oppfylle sine
              forpliktelser vedrørende konkurransen, blant annet for å kontakte
              deltakerne og dele ut premiene. Vinnerne vil kunne annonseres på
              Facebook-sidene til Freia og freia.no, presentert med fornavn og
              eventuelt by. Mottakelse av premie forutsetter at vinneren
              samtykker til dette. Etter vi har fått sendt ut alle premiene blir
              listen med kontaktinformasjon slettet. Listen blir slettet senest
              1 måned etter endt kampanjeperiode.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Du har rett til å tilbakekalle ditt samtykke tilbehandlingen av
              personopplysninger. Tilbakekalles dette samtykket innenvinnerne en
              kåret, anses du ikke lengre å oppfylle kravene for å delta i
              konkurransen, og vi kommer ikke til å benytte dine
              personopplysninger videre. Du har rett til gratis, en gang per
              kalenderår, etter skriftlig underskrevet søknad stilet til
              Mondelez, å få beskjed om hvilke personopplysninger Mondelez har
              om deg og hvordan disse behandles. Etter loven har du også rett
              til å be omkorrigering av eventuelle uriktige personopplysninger
              Mondelez måtte ha.
              <br />
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Dine personopplysninger vil bli delt med nøye utvalgte
              tredjeparter, Consultix som håndterer trekkingen av vinnere, og
              Pulse Communications som håndterer det tekniske rundt
              konkurransen.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              For mer informasjon om behandling av personopplysninger,se her:
              <br />
              <a
                href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=nb-no&siteId=6oyH%2ft29EQgVQQ6KPOLGAQ%3d%3d"
                target="_blank"
              >
                https://eu.mondelezinternational.com/privacy-notice?sc_lang=nb-no&siteId=6oyH%2ft29EQgVQQ6KPOLGAQ%3d%3d
              </a>

              <br />
            </p>

            <br />
            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              KONTAKT
              <br />
              Har du spørsmål om vilkårene eller konkurransen generelt,kan du ta
              kontakt via Mondelez forbrukerkontakt, tlf. 21 53 08 57.
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="terms-footer">
      <h1>
        DELTA I KONKURRANSE
      </h1>
      <router-link to="/">
        <ButtonComponent class="terms-footer__button" :theme="'primary'"
          >DELTA HER</ButtonComponent
        >
      </router-link>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  components: {
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.terms-container {
  position: relative;
  width: 80%;
  max-width: 1100px;
  background-color: white;
  border-radius: 25px;
  margin: auto;
  margin-top: 125px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);

  text-align: center;

  padding: 75px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 90%;
  }

  &__image {
    position: absolute;
    width: 265px;
    left: -12.69%;
    top: 50px;

    @media (max-width: 1375px) {
      display: none;
    }
  }

  &__text {
    max-width: 820px;
    margin: auto;

    &__main-paragraph {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 28.8px;
      margin-bottom: 1.5rem;
    }

    &__sub-paragraph {
      text-align: left;
      line-height: 25px;

      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
      }

      &__subtitle {
        font-weight: 400;
      }
    }
  }

  h1 {
    color: var(--purple);
  }

  h2 {
    color: var(--purple);
    margin-top: 16px;
  }

  p {
    color: var(--purple);
    line-height: 1.6rem;
  }

  &__button-parent {
    margin-top: 75px;
    overflow: hidden;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }

  a {
    line-break: anywhere;
  }
}

.terms-footer {
  position: relative;
  width: 80%;
  max-width: 1100px;
  border-radius: 25px;
  margin: auto;
  overflow-x: hidden;

  text-align: center;
  padding: 75px 0px;

  &__button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }

  a {
    color: var(--secondary);
  }
}
</style>
