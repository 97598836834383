<template>
  <div>
    <HeroBanner2></HeroBanner2>
    <section class="thankyou-container">
      <div class="thankyou-container__text">
        <h1 class="thankyou-container__text__main-paragraph">
          TAKK FOR DIN DELTAKELSE
        </h1>
        <h2>
          Dersom du er dagens vinner, kontakter vi deg direkte. Lykke til!
        </h2>
      </div>

      <div class="thankyou-container__button-parent">
        <a href="https://freia.no/" target="_blank">
          <ButtonComponent class="thankyou-container__button" :theme="'primary'"
            >FREIA.NO</ButtonComponent
          >
        </a>
        <a
          href="https://contactus.mdlzapps.com/freiano/contact/no-NO/"
          target="_blank"
        >
          <ButtonComponent class="thankyou-container__button" :theme="'primary'"
            >KONTAKT OSS</ButtonComponent
          >
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import Error from "@/components/Error.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import HeroBanner2 from "@/components/HeroBanner2.vue";

export default {
  components: {
    Error,
    ButtonComponent,
    HeroBanner2,
  },
};
</script>

<style lang="scss">
.thankyou-container {
  position: relative;
  width: 80%;
  max-width: 1100px;
  border-radius: 25px;
  margin: auto;
  margin-bottom: 50px;

  text-align: center;

  padding: 25px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 90%;
  }

  &__image {
    display: block;
    margin: auto;
    width: 80%;
    max-width: 650px;
  }

  &__text {
    max-width: 800px;
    margin: auto;
    margin-top: 2rem;

    @media (max-width: $mobile) {
      margin-top: 0;
    }
  }

  p {
    color: var(--secondary);
  }

  &__button-parent {
    margin-top: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 50px;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 0px;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }
}
</style>
