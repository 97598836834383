<template>
  <div class="footer">
    <div class="container footer__content">
      <!-- <div class="footer__items">
        <router-link to="/">
          <h3>HJEM</h3>
        </router-link>
        <router-link to="/cookies">
          <h3>COOKIES</h3>
        </router-link>
        <router-link to="/terms">
          <h3>VILKÅR</h3>
        </router-link>
        <a
          href="https://privacy.mondelezinternational.com/eu/no-NO/privacy-notice/"
          target="_blank"
        >
          <h3>PERSONVERNERKLÆRING</h3>
        </a>
        <a
          href="https://contactus.mdlzapps.com/freiano/contact/no-NO/"
          target="_blank"
        >
          <h3>KONTAKT OSS</h3>
        </a>
      </div> -->

      <div class="footer__socials">
        <a href="https://www.facebook.com/freia" target="_blank">
          <div class="footer__socials__facebook"></div>
        </a>

        <a
          href="https://instagram.com/freia_norge?igshid=MzRlODBiNWFlZA=="
          target="_blank"
        >
          <div class="footer__socials__insta"></div>
        </a>

        <a href="https://www.tiktok.com/@freia_norge" target="_blank">
          <div class="footer__socials__tiktok"></div>
        </a>
      </div>

      <div class="footer__company">
        <a href="https://freia.no/" target="_blank">
          <img width="100px" src="@/assets/elements/freialogo.svg" />
        </a>

        <div><h3>&copy; 2024 - FREIA SKAPER GLEDE</h3></div>
      </div>
    </div>

    <div class="footer__border"></div>
    <div class="footer__text">
      <p>
        Denne nettsiden er laget av
        <a class="footer__text__link" href="https://www.pulsecom.no/"
          >Pulse Communications</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss">
.footer {
  padding-top: 2rem;
  background-color: var(--primary);
  font-weight: 900;

  &__content {
    display: grid;
    grid-template-columns: 1fr;

    gap: 1rem;

    @media (min-width: $mobile) {
      justify-content: center;
      align-items: center;
      grid-template-columns: 1.5fr 0.25fr 1fr;
    }
  }

  &__socials {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    @media (max-width: $mobile) {
      justify-content: center;
    }

    &__facebook {
      position: relative;
      z-index: 1;
      background-image: url("@/assets/elements/fb.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 50px;
      height: 50px;

      &:hover {
        background-image: url("@/assets/elements/fb_hover.svg");
      }
    }

    &__insta {
      position: relative;
      z-index: 1;
      background-image: url("@/assets/elements/insta.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 50px;
      height: 50px;

      &:hover {
        background-image: url("@/assets/elements/insta_hover.svg");
      }
    }

    &__tiktok {
      position: relative;
      z-index: 1;
      background-image: url("@/assets/elements/tiktok.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 50px;
      height: 50px;

      &:hover {
        background-image: url("@/assets/elements/tiktok_hover.svg");
      }
    }
  }

  &__company {
    text-align: center;

    @media (max-width: $mobile) {
      margin: 0 auto;
      align-self: center;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    font-size: 0.9rem;

    @media (min-width: $mobile) {
      margin: 0 auto;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem 4rem;
    }

    a,
    button {
      all: unset;
      cursor: pointer;
      width: fit-content;
      text-decoration: none;
      &:focus,
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }
      text-align: start;
    }
  }

  &__border {
    margin-top: 20px;
    width: 100vw;
    height: 1.5px;
    background-color: white;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    text-align: center;

    p {
      font-size: 14px;
      line-height: 23px;
    }

    &__link {
      text-decoration: underline;
    }

    @media (max-width: $mobile) {
      padding: 25px;
    }
  }

  h3 {
    font-size: 0.875rem;
  }

  a,
  p {
    color: white;
  }
}
</style>
