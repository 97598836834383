<template>
  <section class="participate" id="delta">
    <div class="container">
      <div v-if="!submitted">
        <h1>
          REGISTRERING
        </h1>

        <h2 class="participate__subtitle">
          Bli med i konkurransen! Vi trekker en heldig vinner av kjempeegget
          hver dag fra 6. februar til 10. april, registrer deg nedenfor og bli
          med i trekningen. God påske!
        </h2>

        <form
          @submit.prevent="submit"
          data-netlify="true"
          name="freia-paaske-contact"
          class="participate__form"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="freia-paaske-contact" />
          <div class="participate__form-inputs">
            <InputComponent
              :placeholder="labels.firstName"
              required
              v-model="data.firstName"
              :is-valid="validator.fields.firstName.valid"
              name="firstName"
            >
              <!-- <template v-slot:validation-message>
                <Message
                  value="<p>Ikke gyldig fornavn, <br/> vennligst prøv igjen.</p>"
                />
              </template> -->
            </InputComponent>
            <InputComponent
              :placeholder="labels.lastName"
              required
              v-model="data.lastName"
              name="lastName"
              :is-valid="validator.fields.lastName.valid"
            >
              <!-- <template v-slot:validation-message>
                <Message
                  value="<p>Ikke gyldig etternavn, <br/> vennligst prøv igjen.</p>"
                />
              </template> -->
            </InputComponent>
            <InputComponent
              :prefix="data.phone ? '+47' : ''"
              :placeholder="labels.phone"
              required
              v-model="data.phone"
              name="phone"
              :is-valid="validator.fields.phone.valid"
              :validation-message="validator.getFieldError('phone')"
            >
              <!-- <template v-slot:validation-message>
                <Message
                  value="<p>Ikke gyldig telefonnummer, <br/> vennligst prøv igjen.</p>"
                />
              </template> -->
            </InputComponent>
            <InputComponent
              :placeholder="labels.email"
              required
              v-model="data.email"
              name="email"
              :is-valid="validator.fields.email.valid"
              :validation-message="validator.getFieldError('email')"
            >
              <!-- <template v-slot:validation-message>
                <Message value="<p>Ikke gyldig e-post, <br/> vennligst prøv igjen.</p>" />
              </template> -->
            </InputComponent>

            <!--<InputComponent>
              <select required v-model="data.place">
                <option disabled selected :value="null"
                  >Hvor handler du din Freia sjokolade?*</option
                >
                <option>Bunnpris</option>
                <option>Coop Mega</option>
                <option>Coop Prix</option>
                <option>Extra</option>
                <option>Joker</option>
                <option>Kiwi</option>
                <option>Matkroken</option>
                <option>Meny</option>
                <option>Rema 1000</option>
                <option>Spar</option>
              </select>
            </InputComponent>-->
          </div>

          <v-popover
            trigger="click"
            class="popover-component"
            :close="true"
            delay="0"
            offset="0"
            placement="top"
            :disabled="disableCodePopover"
            @hide="disableCodePopover = false"
          >
          </v-popover>

          <CheckboxComponent required v-model="termsAccepted">
            <h2 class="participate__terms-font">
              <span>
                Kryss av her om du godtar
                <router-link to="/Terms"> konkurransebetingelsene</router-link>
                og har fylt 18 år.
              </span>
            </h2>
          </CheckboxComponent>

          <CheckboxComponent required v-model="privacyAccepted">
            <h2 class="participate__terms-font">
              <span>
                Takk for at du deler dine data med oss. Vi behandler alltid
                personlig data på en respektfull og rettferdig måte, klart
                begrenset av formålet til denne kampanjen. Vil du vite mer om
                hvordan vi behandler data, kan du lese mer i vår
                <a
                  href="https://privacy.mondelezinternational.com/eu/no-NO/privacy-notice/"
                  >personvernerklæring</a
                >.
              </span>
            </h2>
          </CheckboxComponent>

          <CheckboxComponent v-model="data.newsletter" name="newsletter">
            <h2 class="participate__terms-font">
              <span>
                Jeg vil gjerne motta nyheter og informasjon fra Freia.
              </span>
            </h2>
          </CheckboxComponent>

          <div class="participate__buttons">
            <ButtonComponent
              label="SEND INN"
              theme="primary"
              success-label="Fullført"
              type="submit"
              :promise="promise"
              error-label="NOE GIKK GALT.."
              loading-label="Laster..."
              :disabled="
                !validator.passes || !privacyAccepted || !termsAccepted
              "
            />
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Validator from "@/mixins/validator";
import { CheckboxComponent } from "vue-elder-checkbox";
import { InputComponent } from "vue-elder-input";
import { ScrollToAnchor } from "@/utils";
import axios from "axios";

// import Message from "@/components/Message.vue";

import { Clone } from "@/utils";

const template = {
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  newsletter: false,
  // place: null,
};

export default {
  mixins: [Validator],
  data() {
    return {
      data: Clone(template),
      termsAccepted: null,
      privacyAccepted: null,
      promise: null,
      disableCodePopover: false,
      submitted: false,
      validator: {
        rules: {
          firstName: "required",
          lastName: "required",
          phone: "required|phone",
          email: "required|email",
          // place: "required",
        },
        labels: "labels",
        data: "data",
      },
    };
  },
  computed: {
    labels() {
      return {
        firstName: "Fornavn*",
        lastName: "Etternavn*",
        email: "E-post*",
        phone: "Telefon*",
      };
    },
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    async submit() {
      if (!this.validator.passes || !this.privacyAccepted) return;
      this.promise = fetch("/.netlify/functions/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.data),
      }).then(() => {
        this.$router.push("/thank-you");
      });

      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      axios.post(
        "/",
        this.encode({
          "form-name": "freia-paaske-contact",
          ...this.data,
        }),
        axiosConfig
      );
      // .then(() => {
      //   this.$router.push("/thank-you");
      // })
      // .catch(() => {
      //   this.$router.push("/error");
      // });
    },
    reset() {
      this.data = Clone(template);
      this.termsAccepted = null;
      this.privacyAccepted = null;
    },

    // async submit() {
    //   if (!this.validator.passes || !this.privacyAccepted) return;
    //   this.promise = fetch("/.netlify/functions/submit", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(this.data),
    //   }).then(() => {
    //     this.$router.push("/thank-you");
    //   });
    // },
    // reset() {
    //   this.data = Clone(template);
    //   this.termsAccepted = null;
    //   this.privacyAccepted = null;
    // },
  },
  components: {
    CheckboxComponent,
    InputComponent,
    // Message,
  },
};
</script>

<style lang="scss">
.participate {
  padding-block: 2rem;
  text-align: center;
  margin-top: 3rem;
  color: var(--purple);
  max-width: 800px;
  margin: auto;
  margin-top: 3rem;

  @media (max-width: $mobile) {
    width: 90%;
  }

  &__subtitle {
    @media (max-width: $mobile) {
      margin-top: 0.5rem;
    }
  }

  span {
    font-family: var(--paragraph-font);

    @media (max-width: $mobile) {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  select {
    border-radius: var(--vue-elder-border-radius) 0 0
      var(--vue-elder-border-radius);
    padding: 0.9em 1.1em;
    font-weight: bold;
    color: #3b1905;
    font-family: "FS ME Bold";
    appearance: none;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;

    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'  stroke='%233b1905' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.25rem;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .elder-button {
      min-width: 20rem;
      margin-bottom: 2rem;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 3rem;
  }

  &__form {
    position: relative;

    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;

    @media (max-width: $mobile) {
      max-width: 100%;
    }

    &-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem 1rem;

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }
  }

  .elder-checkbox {
    margin-bottom: 1.5rem;
    text-align: left;

    &--checked {
      .elder-checkbox__box {
        border-color: var(--purple);
        background: var(--purple);
      }
    }
    align-self: center;

    &__label {
      a {
        text-decoration: underline;
        color: var(--purple);
      }
    }

    &__box {
      margin-right: 1rem;
      border-color: white;
      background: white;
      border-color: var(--purple);
      border-width: 1px;
      border-radius: 50px;
    }
    &__required {
      font-weight: bold;
      margin-left: 2px;
      color: var(--purple);
    }
  }

  .elder-input {
    &__element {
      border-radius: var(--vue-elder-border-radius) 0 0
        var(--vue-elder-border-radius);
      padding: 22.5px;
      font-weight: bold;
      color: var(--purple);
    }

    &__prefix {
      font-weight: bold;
      position: relative;
      border-right: none;
      border-radius: var(--vue-elder-border-radius);
      margin: auto;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        height: 40%;
        width: 2px;
      }
    }
    &__field {
      &--focus {
        border-color: white;
      }
      &--invalid {
        border-color: var(--primary) !important;
      }
    }

    &__validation-message {
      p {
        color: var(--primary);
      }

      position: absolute;
      left: 50%;
      top: 35%;

      transform: translate(-50%, -50%);

      color: white;
      background: var(--primary);
      padding: 5px;
      width: 100%;
      font-weight: 700;
      text-align: center;
      font-size: 20px;
      z-index: 10;
      border-radius: var(--vue-elder-border-radius);
      box-shadow: var(--box-shadow);
      z-index: -1;
      visibility: hidden;

      @keyframes showError {
        0% {
          overflow: hidden;
          visibility: visible;
          z-index: 1;
        }
        99% {
          overflow: hidden;
          visibility: hidden;
          z-index: 1;
        }
        100% {
          overflow: hidden;
          visibility: hidden;
          z-index: -1;
        }
      }

      animation-name: showError;
      animation-duration: 4s;

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
        width: 30px;
        height: 30px;
        path {
          fill: white;
        }
      }
    }
  }

  .v-popover {
    // text-align: right;
    margin: 0.3rem 0;
    // margin-right: 1.5rem;
    .trigger {
      //  font-weight: 300;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
        cursor: pointer;
      }
    }
  }

  &__terms-font {
    font-size: 0.9rem;
    display: inline;
  }
}

.popover {
  position: relative;
  z-index: 3;
  background-color: white;
  color: var(--primary);

  text-align: center;

  padding: 1rem;
  border-radius: 50px;
  max-width: 700px;

  p {
    padding-inline: 3rem;
    font-weight: 700;
  }

  @media (max-width: $mobile) {
    font-size: 0.8em;
    padding: 0.4rem;
  }

  box-shadow: var(--box-shadow);

  img {
    width: 70%;
    object-fit: contain;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;

    width: 30px;
    height: 30px;
    path {
      fill: var(--primary);
    }
  }
}
</style>
