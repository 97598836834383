<template>
  <button class="button" :class="theme">
    <span class="button__label"><slot>Click me</slot></span>
  </button>
</template>

<script>
export default {
  props: {
    theme: String,
  },

  computed: {
    isDesktop() {
      return this.windowWidth > 992 ? true : false;
    },

    themeClass() {
      if (theme === "primary") return "button__primary";
      if (theme === "secondary") return "button__secondary";
    },
  },

  methods: {},

  components: {},
};
</script>

<style lang="scss">
.button {
  background: none;
  background-color: black;
  color: white;
  border: none;
  padding: 0.8rem;
  cursor: pointer;
  min-height: 30px;
  min-width: 60px;
  border-radius: 40px;

  &__primary {
    background-color: var(--primary);
    color: var(--primary-contrast);
  }

  &__secondary {
    background-color: var(--secondary);
    --secondary-contrast: var(--secondary-contrast);
  }

  &__label {
    font-size: 1rem;
    color: inherit;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background-color: var(--primary-contrast);
    color: var(--primary);
  }
}
</style>
